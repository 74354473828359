import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "scheduled-stock-api-beta"
    }}>{`Scheduled stock API (beta)`}</h1>
    <p>{`Scheduled stock reads netex route data files. The files are saved as database structures.
New and changed data are sent as kafka messages based on dated service journey.`}</p>
    <h2 {...{
      "id": "netex-common-file"
    }}>{`Netex common file`}</h2>
    <p>{`This file contains global data for an operator. This includes organizations, vehicle types and route points.`}</p>
    <h2 {...{
      "id": "netex-line-file"
    }}>{`Netex line file`}</h2>
    <p>{`This file specifies information for one train line for an operator. The data includes routes, journey patterns, service journeys and dated service journeys.
The system requires static keys for lines, service journeys and dated service journeys.`}</p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`The Seating beta requires a Bearer Token in the Authorization header created by the Entur Authentication server to be passed for every request.`}</p>
    <h1 {...{
      "id": "guides"
    }}>{`Guides`}</h1>
    <h2 {...{
      "id": "netex-line-file-1"
    }}>{`Netex line file`}</h2>
    <p>{`Defines one train line.`}</p>
    <p>{`Netex id will be used when comparing line definitions. Hence it is necessary that ids are stable.`}</p>
    <h3 {...{
      "id": "line"
    }}>{`Line`}</h3>
    <p>{`Defines a line with service journeys and dated service journeys.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<Line version="6" id="GOA:Line:L50">
  <Name>Oslo-Stavanger</Name>
  <TransportMode>rail</TransportMode>
  <PublicCode>50</PublicCode>
  <PrivateCode>L50</PrivateCode>
  <OperatorRef ref="GOA:Operator:GOA" />
  <RepresentedByGroupRef ref="GOA:Network:GOA" />
</Line>
`}</code></pre>
    <h4 {...{
      "id": "line-name"
    }}>{`Line: Name`}</h4>
    <p>{`Defines the displayed name of the line.`}</p>
    <p><inlineCode parentName="p">{`<Name>Oslo-Stavanger</Name>`}</inlineCode></p>
    <h4 {...{
      "id": "line-transportmode"
    }}>{`Line: TransportMode`}</h4>
    <p>{`Defines the type of transport.`}</p>
    <p><inlineCode parentName="p">{`<TransportMode>rail</TransportMode>`}</inlineCode></p>
    <h4 {...{
      "id": "line-representedbygroupref"
    }}>{`Line: RepresentedByGroupRef`}</h4>
    <p>{`Used to find the operator of the line.`}</p>
    <p><inlineCode parentName="p">{`<RepresentedByGroupRef ref="GOA:Network:GOA" />`}</inlineCode></p>
    <h3 {...{
      "id": "journeypattern"
    }}>{`JourneyPattern`}</h3>
    <p>{`Defines the pattern for a service journey.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<JourneyPattern version="0" id="GOA:JourneyPattern:L50-1">
  <Name>KRS-STV</Name>
  <RouteRef ref="GOA:Route:L50-F" version="0" />
  <pointsInSequence>
    <StopPointInJourneyPattern order="1" version="0" id="GOA:StopPointInJourneyPattern:L50-1-1">
      <ScheduledStopPointRef ref="GOA:ScheduledStopPoint:KRS-1" />
      <ForAlighting>false</ForAlighting>
      <DestinationDisplayRef ref="GOA:DestinationDisplay:L50-STV" />
    </StopPointInJourneyPattern>
    <StopPointInJourneyPattern order="2" version="0" id="GOA:StopPointInJourneyPattern:L50-1-2">
      <ScheduledStopPointRef ref="GOA:ScheduledStopPoint:NDL-2" />
      <RequestStop>true</RequestStop>
    </StopPointInJourneyPattern>
    <StopPointInJourneyPattern order="3" version="0" id="GOA:StopPointInJourneyPattern:L50-1-3">
      <ScheduledStopPointRef ref="GOA:ScheduledStopPoint:MDL-1" />
      <RequestStop>true</RequestStop>
    </StopPointInJourneyPattern>
  </pointsInSequence>
</JourneyPattern>
`}</code></pre>
    <h4 {...{
      "id": "journeypattern-name"
    }}>{`JourneyPattern: Name`}</h4>
    <p>{`Defines the displayed name of the journey pattern.`}</p>
    <h4 {...{
      "id": "journeypattern-stoppointinjourneypattern-scheduledstoppointref"
    }}>{`JourneyPattern: StopPointInJourneyPattern: ScheduledStopPointRef`}</h4>
    <p>{`Maps to ScheduledStopPoint in the shared file`}</p>
    <p><inlineCode parentName="p">{`<ScheduledStopPointRef ref="GOA:ScheduledStopPoint:KRS-1" />`}</inlineCode></p>
    <h4 {...{
      "id": "journeypattern-stoppointinjourneypattern-foralighting"
    }}>{`JourneyPattern: StopPointInJourneyPattern: ForAlighting`}</h4>
    <p>{`If set to false, passengers may not leave the train on this stop.`}</p>
    <p><inlineCode parentName="p">{`<ForAlighting>false</ForAlighting>`}</inlineCode></p>
    <h4 {...{
      "id": "journeypattern-stoppointinjourneypattern-forboarding"
    }}>{`JourneyPattern: StopPointInJourneyPattern: ForBoarding`}</h4>
    <p>{`If set to false, passengers may not board the train on this stop.`}</p>
    <p><inlineCode parentName="p">{`<ForBoarding>false</ForBoarding>`}</inlineCode></p>
    <h3 {...{
      "id": "servicejourney"
    }}>{`ServiceJourney`}</h3>
    <p>{`Defines a journey starting at a specified time.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<ServiceJourney version="1" id="GOA:ServiceJourney:701_197" created="2021-09-29T17:31:51" changed="2021-10-04T19:50:44">
  <Name>701</Name>
  <Description>701 [05:02 : KRS-STV]</Description>
  <PrivateCode>701</PrivateCode>
  <TransportMode>rail</TransportMode>
  <TransportSubmode>
    <RailSubmode>regionalRail</RailSubmode>
  </TransportSubmode>
  <JourneyPatternRef ref="GOA:JourneyPattern:L50-1" version="0" />
  <OperatorRef ref="GOA:Operator:GOA" />
  <LineRef ref="GOA:Line:L50" version="6" />
  <passingTimes>
    <TimetabledPassingTime version="0" id="GOA:TimetabledPassingTime:1-KRS">
      <StopPointInJourneyPatternRef ref="GOA:StopPointInJourneyPattern:L50-1-1" version="0" />
      <DepartureTime>05:02:00</DepartureTime>
    </TimetabledPassingTime>
    <TimetabledPassingTime version="0" id="GOA:TimetabledPassingTime:2-NDL">
      <StopPointInJourneyPatternRef ref="GOA:StopPointInJourneyPattern:L50-1-2" version="0" />
      <ArrivalTime>05:10:00</ArrivalTime>
      <DepartureTime>05:11:00</DepartureTime>
    </TimetabledPassingTime>
  </passingTimes>
  <parts>
    <JourneyPart version="0" id="GOA:JourneyPart:701_197_1">
      <Description>KRS - STV</Description>
      <MainPartRef ref="GOA:JourneyPart:701_197_1" version="0" />
      <TrainNumberRef ref="GOA:TrainNumber:L50-1" version="0" />
      <FromStopPointRef ref="GOA:ScheduledStopPoint:KRS-1" />
      <ToStopPointRef ref="GOA:ScheduledStopPoint:STV-2" />
      <StartTime>05:02:00</StartTime>
      <EndTime>08:05:00</EndTime>
    </JourneyPart>
  </parts>
</ServiceJourney>
`}</code></pre>
    <h4 {...{
      "id": "servicejourney-name"
    }}>{`ServiceJourney: Name`}</h4>
    <p>{`Defines the displayed name of the service journey.`}</p>
    <p><inlineCode parentName="p">{`<Name>701</Name>`}</inlineCode></p>
    <h4 {...{
      "id": "servicejourney-transportmode"
    }}>{`ServiceJourney: TransportMode`}</h4>
    <p>{`Defines the type of transport. Will override value given for line.`}</p>
    <p><inlineCode parentName="p">{`<TransportMode>rail</TransportMode>`}</inlineCode></p>
    <h4 {...{
      "id": "servicejourney-transportsubmode"
    }}>{`ServiceJourney: TransportSubmode`}</h4>
    <p>{`Defines the sub type of transport.`}</p>
    <p><inlineCode parentName="p">{`<RailSubmode>regionalRail</RailSubmode>`}</inlineCode></p>
    <h4 {...{
      "id": "servicejourney-timetabledpassingtime-departuretime"
    }}>{`ServiceJourney: TimetabledPassingTime: DepartureTime`}</h4>
    <p>{`Defined departure time from each stop on a journey. The departure time on the first stop of the journey define the departure time for the journey.`}</p>
    <p><inlineCode parentName="p">{`<DepartureTime>05:02:00</DepartureTime>`}</inlineCode></p>
    <h3 {...{
      "id": "datedservicejourney"
    }}>{`DatedServiceJourney`}</h3>
    <p>{`Defines a journey date of a service journey.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<DatedServiceJourney version="0" id="GOA:DatedServiceJourney:701_KRS-STV_21-10-08" created="2021-09-29T17:31:51">
  <TrainBlockRef ref="GOA:TrainBlock:705-LOD_6443-1" version="0" />
  <ServiceJourneyRef ref="GOA:ServiceJourney:701_197" version="1" />
  <OperatingDayRef ref="GOA:OperatingDay:2021-10-08-Fri" />
</DatedServiceJourney>
`}</code></pre>
    <h4 {...{
      "id": "datedservicejourney-operatingdayref"
    }}>{`DatedServiceJourney: OperatingDayRef`}</h4>
    <p>{`Reference to journey date in the shared file.`}</p>
    <p><inlineCode parentName="p">{`<OperatingDayRef ref="GOA:OperatingDay:2021-10-08-Fri" />`}</inlineCode></p>
    <h4 {...{
      "id": "datedservicejourney-trainblockref"
    }}>{`DatedServiceJourney: TrainBlockRef`}</h4>
    <p>{`Reference to material definition for this journey.`}</p>
    <p><inlineCode parentName="p">{`<TrainBlockRef ref="GOA:TrainBlock:705-LOD_6443-1" version="0" />`}</inlineCode></p>
    <h3 {...{
      "id": "trainblock"
    }}>{`TrainBlock`}</h3>
    <p>{`Defines the material for dated service journeys.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<TrainBlock version="0" id="GOA:TrainBlock:705-LOD_6443-1">
    <blockParts>
        <TrainBlockPart order="1" version="0" id="GOA:TrainBlockPart:705-LOD_6443-1-1">
            <CompoundTrainRef ref="GOA:CompoundTrain:_BFM73-4_BMU73-3_BFR73-2_BM73-1" />
            <journeyParts>
                <JourneyPartRef ref="GOA:JourneyPart:705-LOD_6443_2" version="0" />
            </journeyParts>
        </TrainBlockPart>
        <TrainBlockPart order="2" version="0" id="GOA:TrainBlockPart:705-LOD_6443-1-2">
            <CompoundTrainRef ref="GOA:CompoundTrain:_BM73-1_BFR73-2_BMU73-3_BFM73-4" />
            <journeyParts>
                <JourneyPartRef ref="GOA:JourneyPart:705-LOD_6443_3" version="0" />
            </journeyParts>
        </TrainBlockPart>
    </blockParts>
</TrainBlock>
`}</code></pre>
    <h4 {...{
      "id": "trainblock-trainblockpart"
    }}>{`TrainBlock: TrainBlockPart`}</h4>
    <p>{`Defines material for the dated service journey on one part of the journey.`}</p>
    <h4 {...{
      "id": "trainblock-trainblockpart-compoundtrainref"
    }}>{`TrainBlock: TrainBlockPart: CompoundTrainRef`}</h4>
    <p>{`Reference to the actual train definition in the shared file.`}</p>
    <p><inlineCode parentName="p">{`<CompoundTrainRef ref="GOA:CompoundTrain:_BM73-1_BFR73-2_BMU73-3_BFM73-4" />`}</inlineCode></p>
    <h4 {...{
      "id": "trainblock-trainblockpart-journeypartref"
    }}>{`TrainBlock: TrainBlockPart: JourneyPartRef`}</h4>
    <p>{`Reference to the journey part for which this material is valid. If there are more than one entry in the list, the first one is used.`}</p>
    <p><inlineCode parentName="p">{`<JourneyPartRef ref="GOA:JourneyPart:705-LOD_6443_3" version="0" />`}</inlineCode></p>
    <h2 {...{
      "id": "netex-shared-file"
    }}>{`Netex shared file`}</h2>
    <p>{`This file contains data that is used by a group of line files.`}</p>
    <p>{`Netex id will be used when comparing material definitions and routes. Hence it is necessary that ids are stable.`}</p>
    <h3 {...{
      "id": "authority"
    }}>{`Authority`}</h3>
    <p>{`Defines the authority that controls the corresponding lines.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<Authority version="1" id="GOA:Authority:GOA">
  <CompanyNumber>999999999</CompanyNumber>
  <Name>Go-Ahead Nordic</Name>
  <LegalName>Go-Ahead Nordic AS</LegalName>
  <ContactDetails>
    <Email>post@go-aheadnordic.no</Email>
    <Phone>+47 55 55 55 55</Phone>
    <Url>www.go-aheadnordic.no</Url>
  </ContactDetails>
</Authority>
`}</code></pre>
    <h3 {...{
      "id": "train"
    }}>{`Train`}</h3>
    <p>{`Defines a train or part of a train.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<Train version="0" id="GOA:Train:73_1-2-3-4">
  <Name>type 73</Name>
  <components>
    <TrainComponent order="1" version="0" id="GOA:TrainComponent:73_1-2-3-4-1-A" status="inactive">
      <Label>1</Label>
      <TrainElementRef ref="PEN:TrainElement:GOA-BM73A-COMFORT" versionRef="1" />
      <OperationalOrientation>forwards</OperationalOrientation>
    </TrainComponent>
    <TrainComponent order="2" version="0" id="GOA:TrainComponent:73_1-2-3-4-2-A">
      <Label>2</Label>
      <TrainElementRef ref="PEN:TrainElement:GOA-BFR73A-STANDARD-CAFETERIA" versionRef="1" />
      <OperationalOrientation>forwards</OperationalOrientation>
    </TrainComponent>
    <TrainComponent order="3" version="0" id="GOA:TrainComponent:73_1-2-3-4-3-A">
      <Label>3</Label>
      <TrainElementRef ref="PEN:TrainElement:GOA-BMU73A-STANDARD-ANIMALS-ALLOWED" versionRef="1" />
      <OperationalOrientation>forwards</OperationalOrientation>
    </TrainComponent>
    <TrainComponent order="4" version="0" id="GOA:TrainComponent:73_1-2-3-4-4-A">
      <Label>4</Label>
      <TrainElementRef ref="PEN:TrainElement:GOA-BFM73A-FAMILY" versionRef="1" />
      <OperationalOrientation>forwards</OperationalOrientation>
    </TrainComponent>
  </components>
</Train>
`}</code></pre>
    <h4 {...{
      "id": "train-id"
    }}>{`Train: Id`}</h4>
    <p>{`The hyphened list give the actual carriage numbers of the train.`}</p>
    <p><inlineCode parentName="p">{`id="GOA:Train:73_1-2-3-4"`}</inlineCode></p>
    <h4 {...{
      "id": "train-name"
    }}>{`Train: Name`}</h4>
    <p>{`The display name of the train.`}</p>
    <p><inlineCode parentName="p">{`<Name>type 73</Name>`}</inlineCode></p>
    <h4 {...{
      "id": "train-traincomponent-order"
    }}>{`Train: TrainComponent: Order`}</h4>
    <p>{`The order of the carriage in the train. Counting from 1.`}</p>
    <p><inlineCode parentName="p">{`order="1"`}</inlineCode></p>
    <h4 {...{
      "id": "train-traincomponent-status"
    }}>{`Train: TrainComponent: Status`}</h4>
    <p>{`If set to "inactive", this carriage is closed. Default value is "active".`}</p>
    <p><inlineCode parentName="p">{`status="inactive"`}</inlineCode></p>
    <h4 {...{
      "id": "train-traincomponent-label"
    }}>{`Train: TrainComponent: Label`}</h4>
    <p>{`The carriage number in a train. It must be unique in a train composition.`}</p>
    <p><inlineCode parentName="p">{`<Label>1</Label>`}</inlineCode></p>
    <h4 {...{
      "id": "train-traincomponent-trainelementref"
    }}>{`Train: TrainComponent: TrainElementRef`}</h4>
    <p>{`Reference to a logical element(ref/versionRef) in rolling stock.`}</p>
    <p><inlineCode parentName="p">{`<TrainElementRef ref="PEN:TrainElement:GOA-BM73A-COMFORT" versionRef="1" />`}</inlineCode></p>
    <p>{`This refers to logical element series="GOA-BM73A-COMFORT", version=1. This logical element must exist in rolling stock with status ACTIVE.`}</p>
    <h3 {...{
      "id": "compoundtrain"
    }}>{`CompoundTrain`}</h3>
    <p>{`Defines the composition of one or more trains.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<CompoundTrain version="0" id="GOA:CompoundTrain:_BFM73-4_BMU73-3_BFR73-2_BM73-1">
    <components>
        <TrainInCompoundTrain version="0" id="GOA:TrainInCompoundTrain:73-B_1-2-3-4_1_1">
            <TrainRef ref="GOA:Train:73-B_1-2-3-4" version="0" />
        </TrainInCompoundTrain>
    </components>
</CompoundTrain>
`}</code></pre>
    <h4 {...{
      "id": "compoundtrain-trainincompoundtrain"
    }}>{`CompoundTrain: TrainInCompoundTrain`}</h4>
    <p>{`One of the trains in the compound train.`}</p>
    <h4 {...{
      "id": "compoundtrain-trainincompoundtrain-trainref"
    }}>{`CompoundTrain: TrainInCompoundTrain: TrainRef`}</h4>
    <p>{`Reference to the train in the shared file.`}</p>
    <p><inlineCode parentName="p">{`<TrainRef ref="GOA:Train:73-B_1-2-3-4" version="0" />`}</inlineCode></p>
    <h3 {...{
      "id": "operatingday"
    }}>{`OperatingDay`}</h3>
    <p>{`Defines the calendar date of a day used by a dated service journey.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<OperatingDay version="0" id="GOA:OperatingDay:2021-10-05-Tue">
  <CalendarDate>2021-10-05</CalendarDate>
</OperatingDay>
`}</code></pre>
    <h4 {...{
      "id": "operatingday-calendardate"
    }}>{`OperatingDay: CalendarDate`}</h4>
    <p>{`The actual calendar date of an operating day.`}</p>
    <p><inlineCode parentName="p">{`<CalendarDate>2021-10-06</CalendarDate>`}</inlineCode></p>
    <h3 {...{
      "id": "routepoint"
    }}>{`RoutePoint`}</h3>
    <p>{`Defines a point on a route.
The route points must have a complete and correct list of scheduled stop points. Automatic rebooking when service journeys are changed or replaced,
is based on this information.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<RoutePoint version="0" id="GOA:RoutePoint:OSL">
  <Name>Oslo S</Name>
  <projections>
    <PointProjection version="0" id="GOA:PointProjection:OSL-12">
      <ProjectedPointRef ref="GOA:ScheduledStopPoint:OSL-12" version="0" />
    </PointProjection>
    <PointProjection version="0" id="GOA:PointProjection:OSL-13">
      <ProjectedPointRef ref="GOA:ScheduledStopPoint:OSL-13" version="0" />
    </PointProjection>
    <PointProjection version="0" id="GOA:PointProjection:OSL-3">
      <ProjectedPointRef ref="GOA:ScheduledStopPoint:OSL-3" version="0" />
    </PointProjection>
    <PointProjection version="0" id="GOA:PointProjection:OSL-6">
      <ProjectedPointRef ref="GOA:ScheduledStopPoint:OSL-6" version="0" />
    </PointProjection>
  </projections>
</RoutePoint>
`}</code></pre>
    <h4 {...{
      "id": "routepoint-name"
    }}>{`RoutePoint: Name`}</h4>
    <p>{`The displayed name of the route point.`}</p>
    <p><inlineCode parentName="p">{`<Name>Oslo S</Name>`}</inlineCode></p>
    <h4 {...{
      "id": "routepoint-pointprojection-projectedpointref"
    }}>{`RoutePoint: PointProjection: ProjectedPointRef`}</h4>
    <p>{`The reference used from JourneyPattern in the line file.`}</p>
    <p><inlineCode parentName="p">{`<ProjectedPointRef ref="GOA:ScheduledStopPoint:OSL-12" version="0" />`}</inlineCode></p>
    <h3 {...{
      "id": "scheduledstoppoint"
    }}>{`ScheduledStopPoint`}</h3>
    <p>{`Defines a point on a journey pattern.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<ScheduledStopPoint version="0" id="GOA:ScheduledStopPoint:OSL-6">
  <Name>Oslo S</Name>
</ScheduledStopPoint>
`}</code></pre>
    <h4 {...{
      "id": "scheduledstoppoint-name"
    }}>{`ScheduledStopPoint: Name`}</h4>
    <p>{`The displayed name of the scheduled stop point.`}</p>
    <p><inlineCode parentName="p">{`<Name>Oslo S</Name>`}</inlineCode></p>
    <h3 {...{
      "id": "passengerstopassignment"
    }}>{`PassengerStopAssignment`}</h3>
    <p>{`Defines a place where passengers may enter or leave the train.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<PassengerStopAssignment order="76" version="0" id="GOA:PassengerStopAssignment:OSL-6">
  <ScheduledStopPointRef ref="GOA:ScheduledStopPoint:OSL-6" version="0" />
  <QuayRef ref="NSR:Quay:550" />
</PassengerStopAssignment>
`}</code></pre>
    <h4 {...{
      "id": "passengerstopassignment-quayref"
    }}>{`PassengerStopAssignment: QuayRef`}</h4>
    <p>{`Defines the NSR code of the corresponding quay. Used to find NSR code for Stop Place.`}</p>
    <p><inlineCode parentName="p">{`<QuayRef ref="NSR:Quay:550" />`}</inlineCode></p>
    <h1 {...{
      "id": "scheduled-stock-api-reference"
    }}>{`Scheduled Stock API Reference`}</h1>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/scheduled-stock/v3/api-docs"
      }}>{`See the API reference on Swagger Petstore`}</a></p>
    <Swagger url="https://api.staging.entur.io/scheduled-stock/v3/api-docs" fallback="https://petstore.swagger.io/?url=https://api.staging.entur.io/scheduled-stock/v3/api-docs" mdxType="Swagger" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      